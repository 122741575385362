import {
    ChangeDetectionStrategy,
    Component,
    input,
    output,
    HostListener,
    ElementRef,
    computed,
    signal,
    model,
    inject,
    ViewChild,
    AfterViewChecked,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FlooriControl } from '../../utils';
import { FlooriIconComponent } from '../floori-icon/floori-icon.component';
import { SelectOption } from './select-option';
import { DropdownPosition } from './dropdown-position.enum';
import { FlooriCheckboxComponent } from '../floori-checkbox';
import { MultiselectNamePipe } from './multiselectName/multiselect-name.pipe';

@Component({
    selector: 'floori-select',
    standalone: true,
    imports: [CommonModule, FormsModule, FlooriIconComponent, FlooriCheckboxComponent],
    templateUrl: './floori-select.component.html',
    styleUrl: './floori-select.component.scss',
    providers: [
        MultiselectNamePipe,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FlooriSelectComponent,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlooriSelectComponent
    extends FlooriControl
    implements ControlValueAccessor, AfterViewChecked
{
    private readonly dopdownHeight = 300;
    private readonly multiselectNamePipe = inject(MultiselectNamePipe);

    // select inputs
    readonly options = input.required<SelectOption[]>();
    readonly placeholder = input<string>('');
    readonly multiselect = input(false);

    // search only inputs
    readonly isSearch = input(false);
    readonly noResultMessage = input('');
    readonly selectMessage = input('');
    readonly showValue = input(false);
    readonly showDescription = input(false);
    readonly isRequired = input(false);
    readonly isInForm = input(false);

    readonly selectionChange = output<unknown>();
    readonly opened = output<boolean>();

    readonly searchQuery = model<string>('');
    readonly isOpened = signal(false);

    private _value: unknown = this.multiselect() ? [] : '';

    @ViewChild('dropdown') dropdownElement: ElementRef | null = null;

    dropdownPosition = computed(() => {
        if (!this.isOpened()) return '';

        const inputElement = this.elementRef.nativeElement;
        const inputRect = inputElement.getBoundingClientRect();
        const spaceBelow = window.innerHeight - inputRect.bottom;
        const spaceAbove = inputRect.top;

        return spaceBelow < this.dopdownHeight && spaceAbove > spaceBelow
            ? DropdownPosition.above
            : DropdownPosition.below;
    });

    filteredItems = computed(() => {
        return this.options().filter(
            item =>
                item.label.toLowerCase().includes(this.searchQuery().toLocaleLowerCase()) ||
                (typeof item.value === 'string' &&
                    item.value
                        .toLocaleLowerCase()
                        .includes(this.searchQuery().toLocaleLowerCase())),
        );
    });

    searchPlaceholder = computed(() => {
        return this.isInForm() && this.isOpened() ? this.selectMessage() : this.placeholder();
    });

    selectPlaceholder = computed(() => {
        if (this.isOpened() && this.selectMessage()) {
            return this.selectMessage();
        }

        if (this.multiselect() && this.isArray(this.value)) {
            const selectedOptions = this.multiselectNamePipe.transform(this.value);

            return selectedOptions.length
                ? selectedOptions
                : this.selectedLabel || this.placeholder();
        }

        return this.selectedLabel || this.placeholder();
    });

    showRedAsterisk = computed(() => {
        if (this.isSearch()) {
            return this.isRequired() && !this.isOpened() && this.searchQuery().length === 0;
        }

        return (
            this.isRequired() &&
            !this.isOpened() &&
            typeof this.value === 'string' &&
            this.value.length === 0
        );
    });

    get value(): unknown {
        return this._value;
    }

    set value(val: unknown) {
        if (val !== this._value) {
            this._value = val;
            this.onChange(this._value);
            this.onTouch(this._value);
            this.selectionChange.emit(this._value);
        }
    }

    get selectedLabel(): string {
        return this.options().find(opt => opt.value === this._value)?.label || '';
    }

    constructor(private elementRef: ElementRef) {
        super();
    }

    ngAfterViewChecked(): void {
        if (this.isOpened()) {
            this.positionDropdown();
        }
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isOpened.set(false);
            this.opened.emit(false);
            this.focusChanged(false);
        }
    }

    @HostListener('document:mousewheel', ['$event'])
    onMouseWheel() {
        if (this.isOpened()) {
            this.isOpened.set(false);
            this.opened.emit(false);
            this.focusChanged(false);
        }
    }

    @HostListener('window:resize')
    onWindowResize() {
        this.isOpened.update(isOpen => isOpen);
    }

    toggleDropdown(): void {
        if (!this.disabled) {
            this.isOpened.update(isOpen => !isOpen);
            this.opened.emit(this.isOpened());
            this.focusChanged(this.isOpened());
        }
    }

    showDropdown(): void {
        this.isOpened.set(true);
        this.opened.emit(this.isOpened());
    }

    selectOption(option: SelectOption): void {
        this.value = option.value;
        this.isOpened.set(false);
        this.opened.emit(this.isOpened());
        if (this.isSearch()) {
            this.searchQuery.set(option.label);
        }
    }

    writeValue(value: string | string[]): void {
        if (this.onChangeInit) {
            if (this.multiselect() && Array.isArray(value)) {
                const mappedToSelectOptions = this.mapToSelectOptions(value);
                this.value = mappedToSelectOptions;
                this.isOpened.set(true);
                this.isOpened.set(false); // to refresh if there's existing value
            } else {
                if (this.isSearch() && value === '') {
                    return;
                }

                this.value = value;
                this.isOpened.set(true);
                this.isOpened.set(false); // to refresh if there's existing value

                if (this.isSearch()) {
                    const selectedOption = this.options().find(el => el.value === value);
                    if (selectedOption) this.searchQuery.set(selectedOption?.label);
                }
            }
        }
    }

    handleMultiselectChange(isActive: boolean, option: SelectOption) {
        if (!this.isArray(this.value)) this.value = [];

        if (this.multiselect() && this.isArray(this.value)) {
            if (isActive && !this.value.includes(option)) {
                this.value = [...this.value, option];
            } else if (!isActive) {
                this.value = this.value.filter(item => item !== option);
            }
        }

        if (this.isArray(this.value) && !this.value.length) this.value = '';
    }

    isMultiselectChecked(option: SelectOption): boolean {
        return this.isArray(this.value) && this.value.some(el => el === option);
    }

    private isArray(value: unknown) {
        return Array.isArray(value);
    }

    private mapToSelectOptions(value: string[]): any[] {
        return value
            .map(element => {
                const existingOption = this.options().find(option => option.value === element);
                if (existingOption) return existingOption;
                return null;
            })
            .filter(option => option !== null);
    }

    private positionDropdown(): void {
        const dropdown = this.dropdownElement?.nativeElement;
        if (!dropdown) return;

        const inputElement = this.elementRef.nativeElement;
        const inputRect = inputElement.getBoundingClientRect();
        const dropdownRect = dropdown.getBoundingClientRect();

        const position = this.dropdownPosition();

        dropdown.classList.remove(DropdownPosition.above, DropdownPosition.below);
        if (position) {
            dropdown.classList.add(position);
        }

        let top;
        if (position === DropdownPosition.above) {
            top = inputRect.top - dropdownRect.height;
            if (top < 0) top = 0;
        } else {
            top = inputRect.bottom;
            const maxHeight = Math.min(this.dopdownHeight, window.innerHeight - inputRect.bottom);
            if (top + maxHeight > window.innerHeight) {
                top = Math.max(0, window.innerHeight - maxHeight);
            }
        }

        let left = inputRect.left;
        const width = inputRect.width;
        const rightEdge = left + width;

        if (rightEdge > window.innerWidth) {
            left = Math.max(0, window.innerWidth - width);
        }

        dropdown.style.top = `${top}px`;
        dropdown.style.left = `${left}px`;
        dropdown.style.width = `${width}px`;
    }
}
