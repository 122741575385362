import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@floori-web-components';

@Pipe({
    name: 'multiselectName',
    standalone: true,
})
export class MultiselectNamePipe implements PipeTransform {
    transform(value: SelectOption[]): string {
        if (value && value.length > 0) {
            let result = '';
            value.forEach(option => {
                result += option.label + ', ';
            });

            if (result.endsWith(', ')) {
                result = result.slice(0, -2);
            }

            return result;
        }

        return '';
    }
}
