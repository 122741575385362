import { AbstractControl, ValidatorFn } from '@angular/forms';

export const customValidator = (pattern: RegExp): ValidatorFn => (control: AbstractControl) => {
    if (!control?.value || pattern.test(control.value)) {
        return null;
    }

    control.setErrors({ invalidFormat: true });

    return control.errors;
};
