import { StudioEndpoints } from '@floori-web/interfaces';

export const studioEndpoints = {
    company: {
        vendorId: 'v3/studio/visualization/vendor-id/:subdomain',
        config: 'v3/studio/visualization/config',
    },
    rooms: {
        baseRooms: 'v3/studio/room',
        getRoomTypes: 'get-room-types',
        createRoom: 'v3/studio/room/:visitorId',
    },
    room: {
        arLinks: 'v3/studio/visualization/ar-links/:variantId',
        ctaButtons: 'v3/studio/visualization/cta-buttons',
        questions: 'v3/studio/visualization/product-queston',
        estimation: 'v3/studio/visualization/estimation',
        session: 'v3/studio/visualization/session',
        quote: 'v3/studio/visualization/quote',
        addToCart: 'v3/studio/product/cart',
    },
    product: {
        breadcrumb: 'v3/studio/explore/product/breadcrumbs/:productId',
        filters: 'v3/studio/visualization/product-filters',
        filterProducts: 'v3/studio/explore/filters',
        product: 'v3/studio/explore',
    },
} satisfies StudioEndpoints;
