import { FieldTranslation } from '@floori-web-components';
import { ActionButton } from './action-button';

export enum CustomButtonTypes {
    addToCart = 'addToCart',
    calculator = 'calculator',
    custom = 'custom',
    quote = 'quote',
    devtools = 'devtools',
    sample = 'sample',
    welcomeScreen = 'welcomeScreen',
}

export enum QuoteType {
    standard = 'standard',
    iframe = 'iframe',
}

export enum QuoteFieldTypesEnum {
    telephoneNumber = 'telephone_number',
    email = 'email',
    shortText = 'short_text',
    longText = 'long_text',
    select = 'select',
    multiSelect = 'multi_select',
    integer = 'integer',
    float = 'float',
}

export const selectionTypes = [QuoteFieldTypesEnum.select, QuoteFieldTypesEnum.multiSelect];

export interface ProductConfigurationItem {
    field: string;
    source: string;
}

export interface StudioCtaQuoteValueModel {
    id: string;
    ownerId: string;
    value: FieldTranslation;
    isDefault: boolean;
    order: number;
}

export interface StudioCtaQuoteFieldModel {
    name: FieldTranslation;
    translatedName?: string;
    ownerId: string;
    isRequired: boolean;
    order: number;
    type: QuoteFieldTypesEnum;
    key: string;
    values?: StudioCtaQuoteValueModel[];
}

export class CtaButtonDto implements ActionButton {
    constructor(
        public readonly id: string,
        public readonly visibility: boolean,
        public readonly icon?: string,
        public readonly text?: FieldTranslation,
        public readonly disclaimer?: FieldTranslation,
        public readonly disclaimerRequired?: boolean,
        public readonly email?: string,
        public readonly url?: string,
    ) {}
}

export class QuoteButtonDto extends CtaButtonDto {
    public readonly quoteType?: QuoteType;
    public readonly iframeUrl?: string;
    public readonly isPostalCodeRequired?: boolean;
    public readonly fields?: StudioCtaQuoteFieldModel[];
    public readonly productConfiguration?: ProductConfigurationItem[];
}

export class CalculatorButtonDto extends CtaButtonDto {
    public readonly installationPrice?: string;
    public readonly isRequestQuoteButtonVisible?: boolean;
}

export type CtaConfigDto = {
    [key in CustomButtonTypes]: CtaButtonDto;
};
