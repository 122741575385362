<div
    class="select-input"
    (click)="toggleDropdown()"
    [class.disabled]="disabled"
    [class.focused]="focused">
    <!-- search select -->
    @if (isSearch()) {
    <input
        type="text"
        [(ngModel)]="searchQuery"
        [value]="searchQuery()"
        [placeholder]="searchPlaceholder()"
        (input)="showDropdown()"
        (input)="writeValue('')"
        class="search-input" />
    } @else {
    <!-- select -->
    <span class="selected-value" [ngClass]="{ 'form-style': isInForm() && !value }">
        {{ selectPlaceholder() }}
    </span>
    } @if (showRedAsterisk()) {
    <div class="custom-placeholder">
        <span class="name">{{ placeholder() }}</span>
        <span class="star">*</span>
    </div>
    }

    <floori-icon name="fl_chevron_down_o"></floori-icon>
</div>

@if (isOpened()) {
<div #dropdown class="select-dropdown" [class]="dropdownPosition()">
    <!-- search select -->
    @if (isSearch()) {
    <div class="search-items">
        @if (filteredItems().length > 0) {
        <ul class="items-list">
            @for (item of filteredItems(); track $index) {
            <li (click)="selectOption(item)">
                @if (item.icon) {
                <img [src]="item.icon" alt="icon" loading="lazy" />
                } @else if (item.iconName) {
                <floori-icon [name]="item.iconName"></floori-icon>
                } @else if (item.iconEmoji) {
                <span class="emoji">{{ item.iconEmoji }}</span>
                }
                <div class="item-text">
                    @if (showValue()) {
                    <span class="item-value">{{ item.value }}</span>
                    } @if (showDescription()) {
                    <span class="item-value">{{ item.description }}</span>
                    }

                    <span class="item-label"> {{ item.label }}</span>
                </div>
            </li>
            }
        </ul>
        } @else {
        <p class="items-list no-results">{{ noResultMessage() }}</p>
        }
    </div>
    }
    <!-- select -->
    @else { @for (option of options(); track option.value) { @if (multiselect()) {
    <div class="select-item" [class.selected]="option.value === value">
        <floori-checkbox
            class="align-checkbox-end"
            (checkedChanged)="handleMultiselectChange($event, option)"
            [checked]="isMultiselectChecked(option)">
            <span class="select-item-content">{{ option.label }}</span>
        </floori-checkbox>
    </div>
    } @else {
    <div
        class="select-item"
        [class.selected]="option.value === value"
        (click)="selectOption(option)">
        <span class="select-item-content">{{ option.label }}</span>
    </div>
    } } }
</div>
}
