import { CqrsEvent } from '@floori-web/cqrs';
import {
    EpoxyPatterns,
    FloorPattern,
    GroutSettings,
    ProductExtended,
    ProductTileSize,
    TextureTransitionType,
} from '@floori/models';
import { Size } from '@floori-web/visualization-models';
import { HotpointProductPair } from '../products';

export class ShuffleEvent extends CqrsEvent {
    constructor() {
        super();
    }
}

export class DeleteEvent extends CqrsEvent {
    constructor(public readonly product?: ProductExtended) {
        super();
    }
}

export class RotateEvent extends CqrsEvent {
    constructor(public readonly updateRender: boolean, public readonly angle: number) {
        super();
    }
}

export class TransitionEvent extends CqrsEvent {
    constructor(
        public readonly updateRender: boolean,
        public readonly value: number,
        public readonly type: TextureTransitionType,
    ) {
        super();
    }
}

export class DensityEvent extends CqrsEvent {
    constructor(public readonly density: EpoxyPatterns) {
        super();
    }
}

export class PatternEvent extends CqrsEvent {
    constructor(
        public readonly updateRender: boolean,
        public readonly pattern: FloorPattern,
        public readonly grout?: GroutSettings,
    ) {
        super();
    }
}

export class SizeEvent extends CqrsEvent {
    constructor(
        public readonly product: ProductExtended,
        public readonly size: ProductTileSize,
        public readonly hotpoints: Record<string, HotpointProductPair>,
    ) {
        super();
    }
}

export class UpdateRenderEvent extends CqrsEvent {}

export class PrintScreenEvent extends CqrsEvent {
    constructor(public readonly name?: string) {
        super();
    }
}

export class ShareEvent extends CqrsEvent {}

export class HelpEvent extends CqrsEvent {}

export class ImageSizeEvent extends CqrsEvent {
    constructor(public readonly size: Size) {
        super();
    }
}

export class EnvMapRotationEvent extends CqrsEvent {
    constructor(public readonly x: number, public readonly y: number, public readonly z: number) {
        super();
    }
}

export class CartEvent extends CqrsEvent {
    constructor(public readonly url?: string) {
        super();
    }
}

export class ClearAllEpoxyChips extends CqrsEvent {
    constructor() {
        super();
    }
}
