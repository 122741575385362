<div class="modal-header">
    <h4>{{ text() }}</h4>
    <button type="button" floori-button (click)="closeColorPickerDialog()">
        <floori-icon name="fl_close"></floori-icon>
    </button>
</div>
<div class="modal-body">
    <color-sketch (onChangeComplete)="onColorChange($event)" [color]="selectedColor()" />
    <div class="buttons">
        <button type="button" floori-button class="outline" (click)="closeColorPickerDialog()">
            {{ cancelText() }}
        </button>
        <button type="button" floori-button class="primary" (click)="saveColor()">
            {{ saveText() }}
        </button>
    </div>
</div>
