export const environment = {
    production: false,
    envName: 'development',
    apiUrl: 'https://floori-studio-integrations-eks.actumwork.pl/new-floori',
    cdnUrl: 'https://d3qhrpk421cfxh.cloudfront.net',
    flooriWebsite: 'https://floori.io',
    bookDemoUrl: 'https://floori.io/LANG/contact/',
    supportEmail: 'support@floori.io',
    version: '2025-04-03-788',
    sentryKey: 'https://db9fad6536d3471aba6475e89d4b7d55@sentry.actumwork.pl/7',
    gtmUrl: 'https://floori-gtm-analytics-dev-eks.actumwork.pl',
    gtmKey: 'GTM-WDNP3FQ',
    interactionKey: '',
    showDebugData: false,
    showDevTools: true,
    bonaAdditionsIds: '5443e2ccafafeda4918835cc1563cdbd1f9fc0320df53d57c6e45dbd370f5a6cd34a5867431ce43026a7b2120717a3b7a212',
    bonaCustomHeaderIds: '5e6be57c4af9627eba4325679b683f856c77e16cb668391ca1df92d10ffce42d0561dad2da46378a16171ef53c3026dfa752,91a8402e08fa604e9cf310132cfaf4b17bb01918a3318a19016ed8ccefaf22cfd7a7df10b1c7ffe8dab51e75623ef86198eb,5443e2ccafafeda4918835cc1563cdbd1f9fc0320df53d57c6e45dbd370f5a6cd34a5867431ce43026a7b2120717a3b7a212,8fcbddafc11f3d6c13a81981fca2240c0c2a42632488436cb77b19f1c575a223c5a7f03d941e541d560ec2861b63c5bdf425,7c4dae2c7da8dc18d2354c2b0c2153b4c61808f12583f75749e9220b79f6919a2d0ec62a53e9effa3751ee8feaf3d6e851ef',
    darkThemeSupportIds: '',
};
