import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil, tap } from 'rxjs';
import { FlooriIconComponent } from '../floori-icon';
import { FlooriButtonComponent } from '../floori-button';
import { FlooriControl } from '../../utils';
import { ValidInputTypes } from './models';
import { DisableEnterDirective } from './disable-enter.directive';

@Component({
    selector: 'floori-input',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        FlooriIconComponent,
        FlooriButtonComponent,
        DisableEnterDirective,
    ],
    templateUrl: './floori-input.component.html',
    styleUrls: ['./floori-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FlooriInputComponent,
        },
    ],
})
export class FlooriInputComponent extends FlooriControl implements ControlValueAccessor, OnInit {
    @Input() type: ValidInputTypes = 'text';
    @Input() clear: boolean = true;
    @Input() disableSubmit: boolean = false;
    @Input() updateOn: 'blur' | 'change' = 'change';
    @Input() allowFloats = false;
    @Input() allowNegative = true;
    @Input() showStarInPlaceholder: boolean = false;
    @Output() readonly changed = new EventEmitter<string>();
    private _placeholder = '';
    private _value: unknown = '';
    private readonly floatSeparators = Object.freeze(['.', ',']);
    private _passwordVisible = false;
    private _minValue: number | undefined;
    private _stepValue: string = '1';

    @Input() set value(val: unknown) {
        if (this._value !== null && val !== this.value) {
            this._value = val;
            this.onChange(this._value);
            this.changed.emit(this._value as string);
            this.cdr.detectChanges();
            return;
        }
        this._value = val;
        this.cdr.detectChanges();
    }

    get value(): string {
        return this._value as string;
    }

    @Input() set placeholder(val: string) {
        if (val !== this._placeholder) {
            this._placeholder = !val ? '' : val;
            this.cdr.markForCheck();
        }
    }

    get placeholder(): string {
        return this._placeholder;
    }

    get isPasswordVisible(): boolean {
        return this._passwordVisible;
    }

    get minValue(): number | undefined {
        this._minValue = this.allowNegative ? undefined : 0;
        return this._minValue;
    }

    get stepValue(): string {
        if (this.type === 'number' && this.allowFloats) {
            this._stepValue = '0.01';
        }
        return this._stepValue;
    }

    @HostBinding('class.floori-input') hostClass = true;

    @HostBinding('[disabled]') hostDisabled = 'disabled';

    @HostBinding('[required]') hostRequired = 'required';

    @HostBinding('[readonly]') hostReadonly = 'readonly';

    @HostBinding('class.textarea')
    get isTextArea(): boolean {
        return this.type === 'textarea';
    }

    get isPassword(): boolean {
        return this.type === 'password';
    }

    writeValue(value: unknown): void {
        if (this.onChangeInit) {
            this.value = value;
        }
    }

    clearValue(): void {
        this.input?.nativeElement.focus();
        this.value = '';
    }

    changeVisibility(): void {
        this._passwordVisible = !this._passwordVisible;
    }

    override focusChanged(focused: boolean): void {
        super.focusChanged(focused);
        this._invalid =
            (Object.keys(this.control?.errors || {}).length && this.control?.invalid && !focused) ||
            false;
        this.cdr.markForCheck();
    }

    protected override setupControl(): void {
        super.setupControl();
        if (this.updateOn !== 'change') {
            return;
        }

        this.control?.statusChanges
            ?.pipe(
                takeUntil(this.destroyed),
                tap(() => {
                    this._invalid = (!this.control?.pristine && this.control?.invalid) || false;
                    this.cdr.markForCheck();
                }),
            )
            .subscribe();
    }

    handleKeydown(event: KeyboardEvent): void {
        if (
            this.type === 'number' &&
            !this.allowFloats &&
            this.floatSeparators.includes(event.key)
        ) {
            event.preventDefault();
        }
    }
}
