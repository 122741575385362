import { InjectionToken } from '@angular/core';
import { FlooriWindow } from '../../models';

export const FLOORI_WINDOW = new InjectionToken<FlooriWindow>('floori global window object', {
    factory: (): FlooriWindow => self as unknown as FlooriWindow,
});

export const FLOORI_TRANSLATOR = new InjectionToken('FLOORI_TRANSLATOR');

export const FLOORI_ICON_FAMILY = new InjectionToken('FLOOR_ICON_FAMILY');

export const FLOORI_ENV = new InjectionToken('FLOORI_ENV');

export const FLOORI_ENDPOINTS = new InjectionToken('FLOORI_ENDPOINTS');
