import { CommonModule } from '@angular/common';
import { Component, input, signal } from '@angular/core';

@Component({
    selector: 'floori-step',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './floori-step.component.html',
    styleUrl: './floori-step.component.scss',
})
export class FlooriStepComponent {
    readonly title = input.required<string>();
    readonly isRequired = input.required<boolean>();
    readonly subtitle = input('');

    isActive = signal(false);
}
