import { flooriRoutes } from '@floori-web/constants';
import { CompanyConfig, CompanyConfigDto } from '@floori/models';
import { Inject, Injectable } from '@angular/core';
import { FLOORI_ENDPOINTS, FLOORI_ENV } from '@floori-web-components';
import { FlooriEnv } from '@floori-web/models';
import { MosaicEnv } from '@floori-mosaic/api';

@Injectable()
export class SetupService {
    private readonly pathnameKey = flooriRoutes['preview'];
    private _clientKey = '';

    get clientKey(): string {
        return this._clientKey;
    }

    constructor(
        @Inject(FLOORI_ENV) private environment: FlooriEnv | MosaicEnv,
        @Inject(FLOORI_ENDPOINTS) private endpoints: Record<string, Record<string, string>>,
    ) {}

    bootstrap(): Promise<CompanyConfig> {
        const { hostname, pathname } = window.location;
        const pathSegments = pathname?.split('/').filter(p => p);
        const fromPreview =
            pathSegments?.[0]?.toLowerCase()?.trim() === this.pathnameKey &&
            pathSegments?.length > 1;
        if (fromPreview) {
            this._clientKey = pathSegments?.[1];
            return this.fetchCompanyInfo(this._clientKey);
        }

        const companyName = hostname?.split('.').filter(p => p)?.[0];

        return this.fetchVendorId(companyName).then(vendorId => {
            this._clientKey = vendorId;
            return this.fetchCompanyInfo(this._clientKey);
        });
    }

    private async fetchCompanyInfo(clientKey: string): Promise<CompanyConfigDto> {
        const url = `${this.environment.apiUrl}/${this.endpoints['company']['config']}`;
        return await fetch(url, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${clientKey}`,
            },
        }).then(res => res.json());
    }

    private async fetchVendorId(companyName: string): Promise<string> {
        const url = `${this.environment.apiUrl}/${this.endpoints['company']['vendorId']}`.replace(
            ':subdomain',
            companyName,
        );
        return await fetch(url, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(body => body?.vendorId);
    }
}
