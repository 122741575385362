import { Inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { HtmlRendererService } from '@floori-web/tools';
import {
    COMPANY_VENDOR_ID,
    FLOORI_TRACKING_PROVIDER,
    flooriFonts,
    flooriThemes,
} from '@floori-web/constants';
import { FlooriFontFamily, FlooriThemes } from '@floori/models';
import { delay, forkJoin, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppFacade } from '@floori-web/store';
import {
    ConfigProvider,
    FLOORI_CONFIG_PROVIDER,
    FlooriEnv,
    LocalStorageKeys,
    StorageProvider,
} from '@floori-web/models';
import { BaseEffects } from '@floori-web/utils/abstract';
import { FlooriTrackingProvider } from '@floori-web/tracking';
import {
    FLOORI_ENV,
    FLOORI_STORAGE_PROVIDER,
    FLOORI_TRANSLATOR,
    FlooriIcons,
    flooriSpritePath,
    HashMap,
    Languages,
    TranslationsProvider,
} from '@floori-web-components';
import * as ConfigActions from './config.actions';

@Injectable()
export class ConfigEffects extends BaseEffects {
    private readonly initConfig$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ConfigActions.initConfig),
                withLatestFrom(this.configProvider.companyConfig$),
                tap(([_, config]) => this.appFacade.setSplashScreen(true, config?.logoSplash)),
                switchMap(([, config]) => {
                    if (!Object.keys(config || {}).length) {
                        return of([]);
                    }

                    if (config?.company?.integration?.type) {
                        this.appFacade.setIntegrationType(config?.company?.integration.type);
                    }

                    if (this.flooriEnv.darkThemeSupportIds?.split(',').includes(this.vendorId)) {
                        this.htmlRenderService.addClass(this.htmlRenderService.body, [
                            'floori-dark-theme',
                        ]);
                    }

                    return this.renderClientPersonalisation(
                        config?.theme?.iconFamily || FlooriIcons.empty,
                        config?.theme?.fontFamily || FlooriFontFamily.sans,
                        config?.theme?.theme || FlooriThemes.default,
                        config?.themeCssVars || {},
                    ).pipe(map(() => config));
                }),
                delay(250),
                map(() => this.appFacade.setSplashScreen(false)),
                tap(() => this.appFacade.checkStorageAvailability()),
            ),
        { dispatch: false },
    );

    private readonly changeLang$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ConfigActions.changeLang),
                withLatestFrom(this.configProvider.companyConfig$),
                switchMap(([, config]) => {
                    const lang = config?.currentLang || Languages.en;
                    this.storage.set(LocalStorageKeys.visitorLang, lang).subscribe();
                    return this.translationProvider.changeLanguage(lang);
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private readonly appFacade: AppFacade,
        private readonly htmlRenderService: HtmlRendererService,
        @Inject(FLOORI_ENV) private readonly flooriEnv: FlooriEnv,
        @Inject(COMPANY_VENDOR_ID) private readonly vendorId: string,
        @Inject(FLOORI_CONFIG_PROVIDER) private readonly configProvider: ConfigProvider,
        @Inject(FLOORI_STORAGE_PROVIDER) private readonly storage: StorageProvider,
        @Inject(FLOORI_TRANSLATOR) private readonly translationProvider: TranslationsProvider,
        @Inject(FLOORI_TRACKING_PROVIDER) private readonly trackingProvider: FlooriTrackingProvider,
    ) {
        super();
    }

    private renderClientPersonalisation(
        iconFamily: FlooriIcons,
        fontFamily: FlooriFontFamily,
        themeFamily: FlooriThemes,
        themeSettings?: HashMap<string>,
    ): Observable<void[]> {
        const fontFamilyName = fontFamily?.toLowerCase()?.trim();
        const font = flooriFonts[fontFamilyName as FlooriFontFamily];
        let theme = flooriThemes[themeFamily];
        theme = theme || flooriThemes[FlooriThemes.default];
        return forkJoin([
            this.htmlRenderService.renderLinkTag(
                'prefetch',
                `${flooriSpritePath}/${iconFamily}.svg`,
                'image/svg+xml',
                'image',
            ),
            this.htmlRenderService.renderLinkTag('stylesheet', font?.url || '', 'text/css').pipe(
                map(() => {
                    this.htmlRenderService.addClass(this.htmlRenderService.body, [
                        `floori-${font.family}`,
                    ]);
                }),
            ),
            this.htmlRenderService
                .renderLinkTag('stylesheet', `${theme}.theme.css`, 'text/css')
                .pipe(
                    map(() => {
                        this.htmlRenderService.addClass(this.htmlRenderService.body, [theme]);
                    }),
                ),
        ]).pipe(
            map(() => {
                if (themeSettings) {
                    this.htmlRenderService.addCssVars(themeSettings);
                }

                return [];
            }),
        );
    }
}
