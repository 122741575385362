import { Observable } from 'rxjs';

export enum LocalStorageKeys {
    visitorId = 'visitorId',
    visitorLang = 'visitorLang',
    likedProducts = 'likedProducts',
    tutorialSeen = 'tutorialSeen',
    rugInfo = 'rugInfo',
    roomFeatureInfo = 'roomFeatureInfo',
    roomsFeatureInfo = 'roomsFeatureInfo',
    draftPopupClosed = 'draftPopupClosed',
    cookies = 'cookies',
    cartData = 'cartData',
}

export enum StorageType {
    session = 'session',
    local = 'local',
}
export interface StorageProvider {
    set<T = string>(
        key: LocalStorageKeys | string,
        value: T,
        type?: StorageType,
    ): Observable<boolean>;

    get<T = string>(key: LocalStorageKeys | string, type?: StorageType): Observable<T | null>;

    delete(key: LocalStorageKeys | string, type?: StorageType): Observable<boolean>;

    getSync<T = string>(key: string, type?: StorageType): T | null;
}
