import { Component, Inject, output, signal } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { ColorSketchModule } from 'ngx-color/sketch';
import { FlooriButtonComponent, FLOORI_DIALOG_DATA, FlooriDialogRef } from '../../components';
import { FlooriIconComponent } from '../../components/floori-icon/floori-icon.component';
import { ColorPickerData } from '../../models/color-picker-data.model';

@Component({
    selector: 'floori-floori-color-picker-dialog',
    standalone: true,
    imports: [ColorSketchModule, FlooriButtonComponent, FlooriIconComponent],
    templateUrl: './floori-color-picker-dialog.component.html',
    styleUrl: './floori-color-picker-dialog.component.scss',
})
export class FlooriColorPickerDialogComponent {
    readonly text = signal<string>('');
    readonly selectedColor = signal<string>('');
    readonly cancelText = signal<string>('');
    readonly saveText = signal<string>('');

    readonly colorChanged = output<string>();

    constructor(
        @Inject(FLOORI_DIALOG_DATA) dialogData: ColorPickerData,
        private readonly dialogRef: FlooriDialogRef<string>,
    ) {
        this.text.set(dialogData.text);
        this.cancelText.set(dialogData.cancelText);
        this.saveText.set(dialogData.saveText);
        this.selectedColor.set(dialogData.selectedColor);
    }

    closeColorPickerDialog(): void {
        this.dialogRef.close();
    }

    onColorChange($event: ColorEvent): void {
        const value = $event?.color?.hex || '';
        this.selectedColor.set(value);
        this.colorChanged.emit(value);
    }

    saveColor(): void {
        this.dialogRef.close(this.selectedColor());
    }
}
