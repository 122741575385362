<ng-content select="[common-content]"></ng-content>

<div class="stepper-header" [ngClass]="{ vertical: isVertical() }">
    @for (step of allSteps(); track $index) {
    <div [ngClass]="{ active: step.isActive, completed: step.isCompleted }" class="step">
        <floori-icon [name]="step.icon"></floori-icon>
        <div class="text">
            <span class="title">{{ step.title }}</span>
            <span class="subtitle">{{ step.subtitle }}</span>
        </div>
    </div>

    @if (!$last) {
    <div class="step-line"></div>
    } }
</div>

<div class="stepper-container">
    <ng-content select="[step-content]"></ng-content>
</div>
