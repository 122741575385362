import { InjectionToken } from '@angular/core';
import {
    BreadcrumbItemDto,
    PagedResponse,
    ProductExtended,
    ProductFilterDefinitionDto,
    ProductFilterResponseDto,
    ProductsFilterData,
} from '@floori/models';
import { ProductFetchParams } from '@floori/models';
import { Observable } from 'rxjs';

export const FLOORI_PRODUCT_API = new InjectionToken('FLOORI_PRODUCT_API');

export interface ProductApiProvider {
    fetchProduct(
        options?: ProductFetchParams,
        metadata?: Record<string, unknown>,
    ): Observable<ProductExtended | undefined>;

    fetchBreadcrumb(productId?: string, metadata?: string): Observable<BreadcrumbItemDto[]>;

    fetchFilterDefinitions(isLiteRequest?: boolean): Observable<ProductFilterDefinitionDto[]>;

    filterProducts(
        filters: ProductsFilterData,
        metadata?: Record<string, unknown>,
    ): Observable<PagedResponse<ProductFilterResponseDto>>;
}
