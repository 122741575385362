import {
    AfterViewInit,
    Component,
    computed,
    ContentChildren,
    input,
    output,
    QueryList,
    signal,
} from '@angular/core';
import { FlooriStepComponent } from '../floori-step/floori-step.component';
import { CommonModule } from '@angular/common';
import { FlooriIconComponent } from '../floori-icon/floori-icon.component';
import { StepperType } from '@floori-web-components';

@Component({
    selector: 'floori-stepper',
    standalone: true,
    imports: [CommonModule, FlooriIconComponent],
    templateUrl: './floori-stepper.component.html',
    styleUrl: './floori-stepper.component.scss',
})
export class FlooriStepperComponent implements AfterViewInit {
    @ContentChildren(FlooriStepComponent) steps!: QueryList<FlooriStepComponent>;
    readonly currentStep = signal(0);
    readonly totalSteps = signal(0);

    readonly type = input<StepperType>('horizontal');
    readonly stepChanged = output<number>();

    readonly allSteps = computed(() => {
        return this.steps.toArray().map((step, index) => ({
            number: index + 1,
            title: step.title(),
            subtitle: step.subtitle(),
            isRequired: step.isRequired(),
            isActive: index === this.currentStep(),
            isCompleted: index < this.currentStep(),
            icon: index < this.currentStep() ? 'fl_check_circle_s' : 'fl_stop_circle_s',
        }));
    });

    readonly isVertical = computed(() => {
        return this.type() === 'vertical';
    });

    ngAfterViewInit() {
        const stepsArray = this.steps.toArray();
        this.totalSteps.set(stepsArray.length);
        if (stepsArray.length > 0) {
            stepsArray[this.currentStep()].isActive.set(true);
        }
    }

    next() {
        if (this.currentStep() < this.steps.length - 1) {
            const stepsArray = this.steps.toArray();
            stepsArray[this.currentStep()].isActive.set(false);

            this.currentStep.update(prev => prev + 1);
            stepsArray[this.currentStep()].isActive.set(true);

            this.stepChanged.emit(this.currentStep());
        }
    }

    previous() {
        if (this.currentStep() > 0) {
            const stepsArray = this.steps.toArray();
            stepsArray[this.currentStep()].isActive.set(false);

            this.currentStep.update(prev => prev - 1);
            stepsArray[this.currentStep()].isActive.set(true);

            this.stepChanged.emit(this.currentStep());
        }
    }
}
