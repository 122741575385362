<ng-content select=".floori-input-prefix"></ng-content>

<textarea
    *ngIf="type === 'textarea'"
    [flooriDisableEnter]="disableSubmit"
    [placeholder]="placeholder"
    (focus)="focusChanged(true)"
    (blur)="focusChanged(false)"
    (input)="value = $any($event.target)?.value"
    [value]="value"
    [disabled]="disabled"
    [readonly]="readonly"
    [required]="required"></textarea>

<input
    *ngIf="type !== 'textarea'"
    [flooriDisableEnter]="disableSubmit"
    [type]="isPasswordVisible ? 'text' : type"
    [placeholder]="placeholder"
    (focus)="focusChanged(true)"
    (blur)="focusChanged(false)"
    (input)="value = $any($event.target)?.value"
    (keydown)="handleKeydown($event)"
    [min]="minValue"
    [value]="value"
    [disabled]="disabled"
    [readonly]="readonly"
    [required]="required"
    [step]="stepValue" />

<div *ngIf="clear && !disabled && !readonly" class="floori-input-clear" (click)="clearValue()">
    <button *ngIf="value" floori-icon-button tabindex="-1">
        <floori-icon name="fl_close"></floori-icon>
    </button>
</div>

<div *ngIf="isPassword" class="floori-input-toggle-password">
    <button type="button" floori-icon-button (click)="changeVisibility()" tabindex="-1">
        <floori-icon
            *ngIf="isPasswordVisible"
            name="fl_eye_slash_o"
            [flooriDisableEnter]="true"></floori-icon>
        <floori-icon
            *ngIf="!isPasswordVisible"
            name="fl_eye_o"
            [flooriDisableEnter]="true"></floori-icon>
    </button>
</div>
<div class="placeholder" *ngIf="showStarInPlaceholder && value?.length === 0">
    <span class="name">{{ placeholder }}</span>
    <span class="star">*</span>
</div>

<ng-content select=".floori-input-appendix"></ng-content>
