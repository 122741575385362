export enum FlooriTrackingEventsList {
    roomSelect = 'room_select',
    roomUploaded = 'room_uploaded',
    pageView = 'page_view',
    productNotFound = 'product_not_found',
    uploadPhotoOrientation = 'upload_photo_orientation',
    noConnection = 'no_connection',
    visualizationDownload = 'visualization_download',
    addToFav = 'add_to_favorites',
    productVizualization = 'product_vizualization',
    open3DModelMobile = 'open_3d_model_mobile',
    open3DModelDesktop = 'open_3d_model_desktop',
}

export enum FlooriTrackingButtonList {
    welcomeCTA = 'welcome_cta',
    languageSelect = 'language_select',
    uploadPhoto = 'upload_photo',
    roomFilter = 'room_filter',
    consentChange = 'consent_change',

    requestQuote = 'request_quote',
    getSample = 'get_sample',
    customButton = 'custom_button',

    deleteProduct = 'delete_product',
    addToCart = 'add_to_cart',
    openARModel = 'open_ar_model',
    openARModelVisualization = 'open_ar_model_visualization',
    compare = 'compare',
    calculator = 'calculator',

    openFilters = 'open_filters',
    applyFilters = 'apply_filters',
    listView = 'list_view',
    gridView = 'grid_view',

    catalog = 'catalog',
}
