import { Component, inject, input, output, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FlooriDialogService } from '../floori-dialog';
import { ColorPickerData } from '../../models/color-picker-data.model';
import { flooriHelperClasses } from '../../constants/floori-helper-classes';
import { FlooriColorPickerDialogComponent } from '../floori-color-picker-dialog/floori-color-picker-dialog.component';

@Component({
    selector: 'floori-color-picker',
    standalone: true,
    imports: [],
    providers: [
        FlooriDialogService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FlooriColorPickerComponent,
            multi: true,
        },
    ],
    templateUrl: './floori-color-picker.component.html',
    styleUrl: './floori-color-picker.component.scss',
})
export class FlooriColorPickerComponent implements ControlValueAccessor {
    private readonly dialogService = inject(FlooriDialogService);

    readonly color = input<string>('#000000');
    readonly header = input<string>('colorPicker');
    readonly cancelText = input('');
    readonly saveText = input('');

    readonly colorChange = output<string>();

    readonly currentColor = signal(this.color());

    writeValue(value: string): void {
        this.currentColor.set(value);
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    openColorPicker(): void {
        this.dialogService
            .open<FlooriColorPickerDialogComponent, ColorPickerData, string>(
                FlooriColorPickerDialogComponent,
                {
                    backdropClass: flooriHelperClasses['darkOverlay'],
                    panelClass: flooriHelperClasses['centerOverlay'],
                    data: {
                        text: this.header(),
                        selectedColor: this.currentColor(),
                        cancelText: this.cancelText(),
                        saveText: this.saveText(),
                    },
                },
            )
            .beforeClosed()
            .subscribe(result => {
                if (result) {
                    this.currentColor.set(result);
                    this.colorChange.emit(result);
                    this.onChange(result);
                    this.onTouched();
                }
            });
    }

    private onChange: (value: string) => void = () => {}; //required by ControlValueAccessor
    private onTouched: () => void = () => {}; //required by ControlValueAccessor
}
