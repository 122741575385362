import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ActionButton,
    ArLinksDto,
    EstimationDto,
    ProductQuestion,
    SendQuoteDto,
} from '@floori/models';
import { RoomActionBtn } from '../room';
import { CartDataDto } from './cart-data.dto';
import { CartData } from './cart-data';

export const FLOORI_ROOM_API = new InjectionToken('FLOORI_ROOM_API');

export interface RoomApiProvider {
    addSession(roomId: string): Observable<void>;

    fetchCtaButtons<T extends ActionButton>(): Observable<RoomActionBtn<T>[]>;

    fetchQuestions(): Observable<ProductQuestion[]>;

    fetchEstimation(roomId: string, visitorId: string): Observable<EstimationDto>;

    getArLinksUrl(formData: FormData, variantId: string): Observable<ArLinksDto>;

    sendQuote(quote: SendQuoteDto): Observable<void>;

    addToCart(data: CartDataDto): Observable<CartData>;
}
