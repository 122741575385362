import { CompanyConfig } from '@floori/models';

export const bonaAdditions = (config: CompanyConfig): Partial<CompanyConfig> => ({
    themeCssVars: {
        ...config.themeCssVars,
        primary: '#00205C',
        primary100: '#EEF2FD',
    },
    dynamicFeatures: {
        translationScope: 'bona',
        hideProductDetails: true,
        roomsMessage: 'features.rooms.welcomeMessage',
        roomMessage: 'features.room.welcomeMessage',
        roomProductMessage: 'features.room.productMessage',
    },
});

export const bonaCustomHeader = (config: CompanyConfig): Partial<CompanyConfig> => ({
    dynamicFeatures: {
        translationScope: 'bona',
        marketingHeader1: 'features.rooms.marketingHeader1',
        marketingHeader2: 'features.rooms.marketingHeader2',
        marketingSubHeader: 'features.rooms.marketingSubHeader',
    },
});
