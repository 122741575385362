import { CustomButtonTypes } from '@floori/models';

export enum RoomActionButtonType {
    catalog = 'catalog',
    downloadScreen = 'downloadScreen',
    favourite = 'favourite',
    cart = 'cart',
    settings = 'settings',
    share = 'share',
    help = 'help',
    loading = 'loading',
}

export const roomActionButtonsLabels: Record<RoomActionButtonType, string> = {
    [RoomActionButtonType.catalog]: 'room.products.header',
    [RoomActionButtonType.settings]: 'room.settings.header',
    [RoomActionButtonType.help]: 'room.help.header',
    [RoomActionButtonType.favourite]: 'room.favourite.header',
    [RoomActionButtonType.cart]: 'room.cart.header',
    [RoomActionButtonType.share]: 'room.share.header',
    [RoomActionButtonType.downloadScreen]: 'room.download.header',
    [RoomActionButtonType.loading]: '',
};
export const customActionButtonsLabels: Record<CustomButtonTypes, string> = {
    [CustomButtonTypes.quote]: 'quote.standardTitle',
    [CustomButtonTypes.calculator]: 'calculator.title',
    [CustomButtonTypes.addToCart]: 'room.selectedProducts.label',
    [CustomButtonTypes.custom]: '',
    [CustomButtonTypes.devtools]: 'devtools',
    [CustomButtonTypes.sample]: 'sample.standardTitle',
    [CustomButtonTypes.welcomeScreen]: '',
};

export const roomActionButtonsIcons: Record<RoomActionButtonType, string> = {
    [RoomActionButtonType.catalog]: 'fl_catalog_o',
    [RoomActionButtonType.settings]: 'fl_pattern_o',
    [RoomActionButtonType.help]: 'fl_question_mark_circle_o',
    [RoomActionButtonType.favourite]: 'fl_heart_o',
    [RoomActionButtonType.cart]: 'fl_cart_o',
    [RoomActionButtonType.share]: 'fl_share_o',
    [RoomActionButtonType.downloadScreen]: 'fl_arrow_down_tray_o',
    [RoomActionButtonType.loading]: '',
};

export const customActionButtonsIcons: Record<CustomButtonTypes, string> = {
    [CustomButtonTypes.custom]: '',
    [CustomButtonTypes.quote]: 'fl_quote_o',
    [CustomButtonTypes.calculator]: 'fl_calculator_o',
    [CustomButtonTypes.addToCart]: 'fl_hotpoint_o',
    [CustomButtonTypes.devtools]: 'fl_devtools_o',
    [CustomButtonTypes.sample]: '',
    [CustomButtonTypes.welcomeScreen]: '',
};
