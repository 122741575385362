import { Directive, HostListener, Inject } from '@angular/core';
import { FlooriStepperComponent } from '@floori-web-components';

@Directive({
    selector: '[flooriStepperPrevious]',
    standalone: true,
})
export class FlooriStepperPreviousDirective {
    constructor(@Inject(FlooriStepperComponent) private stepper: FlooriStepperComponent) {}

    @HostListener('click') onClick() {
        this.stepper.previous();
    }
}
